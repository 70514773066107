<template lang="pug">
//- display mode
div(class="d-flex align-center pb-6")
    div(
        @click="displayMode = 'full'"
        class="mr-4"
    )
        div(
            style="width: 30px; height: 30px"
            :style="displayMode == 'full' ? { 'background-color': 'var(--ion-color-secondary)' } : { 'background-color': 'var(--ion-color-accent)' }"
        )
    div(
        @click="displayMode = 'list'"
    )
        div(
            style="width: 30px; height: 30px"
            class="d-flex flex-column justify-space-between"
        )
            div(
                :style="displayMode == 'list' ? { 'background-color': 'var(--ion-color-secondary)' } : { 'background-color': 'var(--ion-color-accent)' }"
                style="width: 100%; height: 6px"
            )
            div(
                :style="displayMode == 'list' ? { 'background-color': 'var(--ion-color-secondary)' } : { 'background-color': 'var(--ion-color-accent)' }"
                style="width: 100%; height: 6px"
            )
            div(
                :style="displayMode == 'list' ? { 'background-color': 'var(--ion-color-secondary)' } : { 'background-color': 'var(--ion-color-accent)' }"
                style="width: 100%; height: 6px"
            )

//- full
div(
    v-show="displayMode == 'full'"
)
    slot(
        name="full"
    )

//- list
div(
    v-show="displayMode == 'list'"
)
    slot(
        name="list"
    )
</template>

<script>
import { ref } from "vue"
export default {
    setup() {
        const displayMode = ref(["list"]) // 'full' || 'list'

        function toggleToFullThumb() {
            displayMode.value = "full"
        }

        return { displayMode, toggleToFullThumb }
    }
}
</script>