<template lang="pug">
div(
    style="width: 100%; height: 100%; background-color: var(--ion-color-quinary)"
    class="radius overflow-hidden"
    v-if="!!video"
)
    div(
        class="d-flex justify-center align-center relative"
        style="width: 100; height: 100%"
    )
        div {{ video.name }}

        //- remove button
        IonButton(
            class="icon-btn z-index-1"
            color="secondary"
            style="position: absolute; bottom: 10px; right: 10px"
            @click="$emit('openRemoveModal')"
        )
            IonIcon(
                :icon="trashOutline"
                color="white"
            )
        img(
            :src="video.thumb"
            style="position: absolute; bottom: 0; right: 0; width: 100%; height: 100%; object-fit: cover"
        )
</template>

<script>
import { trashOutline } from "ionicons/icons"

export default {
    props: {
        video: {
            type: [Object, null]
        }
    },

    setup() {
        trashOutline

        return {
            trashOutline
        }
    }
}
</script>