<template lang="pug">
div(
    v-if="!!order"
)
    //- name bar
    TopBar
        OrderName(
            :order="order"
        )

    //- contact
    ContactBoxes(
        :order="order"
    )

    //- tabs
    div
        Spacer(
            small
        )
        Container
            IonSegment(
                v-model="tab"
                :scrollable="true"
            )
                //- color="secondary"
                IonSegmentButton(
                    value="video"
                )
                    IonLabel(
                        color="white"
                    ) Video
                IonSegmentButton(
                    value="images"
                )
                    IonLabel(
                        color="white"
                    ) Bilder
                IonSegmentButton(
                    value="remarks"
                )
                    IonLabel(
                        color="white"
                    ) Anmerkung

        SegmentCard(
            style="background-color: var(--ion-color-primary-shade)"
        )
            div(
                v-if="tab == 'video'"
            )
                VideosModule(
                    :order="order"
                )

            div(
                v-if="tab == 'images'"
            )
                ImagesModule(
                    :order="order"
                )

            div(
                v-if="tab == 'remarks'"
                class="relative"
            )
                div(
                    style="padding-right: 35px"
                ) {{ order.remarks }}
                NoUploads(
                    v-if="!order.remarks"
                )
                    div Keine Anmerkungen
                IonButton(
                    class="round-btn"
                    color="secondary"
                    style="width: 30px; height: 30px; position: absolute; right: 0; top: -10px"
                    @click="openEditRemarksModal"
                )
                    CustomIcon(
                        fileName="edit.png"
                    )

    //- buttons
    div(class="buttons-height-1")
        //- div(class="page-buttons-wrapper")
        //-     Container
        //-         IonRow(class="receding btn-container")
        //-             IonCol(
        //-                 size="12"
        //-             )
        //-                 IonButton(
        //-                     color="secondary"
        //-                     size="large"
        //-                     expand="block"
        //-                     @click="$router.push({ name: 'order-inventory-report', params: { id: order.id } })"
        //-                 )
        //-                     IonText(
        //-                         color="primary"
        //-                     )
        //-                         h3 Bericht Anzeigen

    CustomModal(
        v-model="editRemarksModal"
    )
        ConfirmModalContentWithSlot(
            @confirm="updateRemarks(order, editedText)"
            @decline="closeEditRemarksModal"
            secondVariant
        )
            div(
                class="d-flex flex-column"
                style="height: 100%"
            )
                Spacer
                IonTextarea(
                    style="flex-grow: 1; --background: white"
                    v-model="editedText"
                    class="radius"
                )
</template>

<script>
import TopBar from "@/components/sections/TopBar"
import ContactBoxes from "@/components/sections/ContactBoxes"
import OrderName from "@/components/molecules/OrderName"
import CheckableButton from "@/components/molecules/CheckableButton"
import VideosModule from "@/components/molecules/camera-uploads/VideosModule"
import ImagesModule from "@/components/molecules/camera-uploads/ImagesModule"
import SegmentCard from "@/components/molecules/SegmentCard"
import CustomIcon from "@/components/molecules/CustomIcon"
import CustomModal from "@/components/molecules/modals/CustomModal"
import ConfirmModalContentWithSlot from "@/components/molecules/modals/ConfirmModalContentWithSlot"
import NoUploads from "@/components/molecules/camera-uploads/NoUploads"

import { ref } from "vue"
import { useUtilities } from "@/composables/utilities"
import { useState } from "@/composables/state.js"
export default {
    components: {
        TopBar,
        OrderName,
        ContactBoxes,
        CheckableButton,
        VideosModule,
        SegmentCard,
        ImagesModule,
        CustomIcon,
        CustomModal,
        ConfirmModalContentWithSlot,
        NoUploads
    },
    props: {
        order: {
            required: true
        }
    },

    setup(props) {
        const tab = ref("video")

        const emitter = useUtilities().getEmitter()

        // remarks edit
        const editedText = ref(null)
        const editRemarksModal = ref(false)

        const { updateOrder } = useState()

        function openEditRemarksModal() {
            editRemarksModal.value = true
            editedText.value = JSON.parse(JSON.stringify(props.order.remarks))
        }

        function closeEditRemarksModal() {
            editRemarksModal.value = false
            editedText.value = null
        }

        async function updateRemarks() {
            emitter.emit("setLoadingModal", true)
            const orderCopy = JSON.parse(JSON.stringify(props.order))
            orderCopy.remarks = editedText.value
            await updateOrder(orderCopy)
            setTimeout(() => {
                emitter.emit("setLoadingModal", false)
            }, 1000)
            setTimeout(() => {
                emitter.emit("showSuccessModal")
            }, 1000)
            closeEditRemarksModal()
        }

        return {
            tab,
            editRemarksModal,
            updateRemarks,
            editedText,
            openEditRemarksModal,
            closeEditRemarksModal
        }
    }
}
</script>