<template lang="pug">
BaseLayout
    OrderInventory(
        :order="currentOrder"
    )
</template>

<script>
import OrderInventory from "@/components/sections/order-processing/OrderInventory"
import { useUtilities } from "@/composables/utilities"

export default {
    components: {
        OrderInventory
    },

    setup() {
        const { currentOrder } = useUtilities()
        return { currentOrder }
    }
}
</script>