<template lang="pug">
div
    MediaViewer(
        ref="mediaViewer"
    )
        template(
            v-slot:full
        )
            vueper-slides(
                :slide-ratio="3 / 4"
                :arrow="false"
                :bullets="false"
                :infinite="false"
                ref="fullThumbsCarousel"
                v-if="order.images?.length"
            )
                vueper-slide(
                    v-for="(fullThumbImage, index) in order.images"
                    :key="index"
                )
                    template(
                        v-slot:content
                    )
                        ImageThumbFull(
                            :image="fullThumbImage"
                            @openRemoveModal="openRemoveModal(fullThumbImage.id)"
                        )

            NoUploads(
                v-if="!order.images?.length"
            )
                div Keine Bilder
        template(
            v-slot:list
        )
            div(
                v-for="(smallThumbImage, index) in order.images"
                :key="index"
                :class="index != order.images?.length - 1 ? 'mb-3' : ''"
            )
                ImageThumbSmall(
                    :image="smallThumbImage"
                    @openRemoveModal="openRemoveModal(smallThumbImage.id)"
                    @toggleToFullThumb="toggleToFullThumb(smallThumbImage.id)"
                )

            NoUploads(
                v-if="!order.images?.length"
            )
                div Keine Bilder

    IonRow(class="receding mt-3")
        IonCol(
            size="12"
        )
            IonButton(
                color="secondary"
                size="large"
                expand="block"
                @click="takePicture"
            )
                IonText(
                    color="white"
                )
                    h3 Mach ein Foto

CustomModal(
    v-model="removeModalOpened"
)
    ConfirmModalContent(
        @confirm="removeImageProceed"
        @decline="closeRemoveModal"
    )
</template>

<script>
import { ref } from "vue"
import { useUtilities } from "@/composables/utilities"
import MediaViewer from "@/components/molecules/camera-uploads/MediaViewer"
import ImageThumbFull from "@/components/molecules/camera-uploads/ImageThumbFull"
import ImageThumbSmall from "@/components/molecules/camera-uploads/ImageThumbSmall"
import NoUploads from "@/components/molecules/camera-uploads/NoUploads"
import CustomModal from "@/components/molecules/modals/CustomModal"
import ConfirmModalContent from "@/components/molecules/modals/ConfirmModalContent"

import { VueperSlides, VueperSlide } from "vueperslides"
import "vueperslides/dist/vueperslides.css"

import { useState } from "@/composables/state"

export default {
    components: {
        MediaViewer,
        ImageThumbFull,
        ImageThumbSmall,
        NoUploads,
        CustomModal,
        ConfirmModalContent,
        VueperSlides,
        VueperSlide
    },

    props: {
        order: Object
    },
    setup(props) {
        // bus
        const emitter = useUtilities().getEmitter()

        // display
        const mediaViewer = ref(null)

        function toggleToFullThumb(id) {
            mediaViewer.value.toggleToFullThumb()
        }

        // recording
        function takePicture() {}

        // modals
        const imageToRemoveId = ref()

        const removeModalOpened = ref(false)

        function openRemoveModal(id) {
            imageToRemoveId.value = id
            removeModalOpened.value = true
        }

        function closeRemoveModal() {
            imageToRemoveId.value = null
            removeModalOpened.value = false
        }

        const { removeImage } = useState()

        function removeImageProceed() {
            removeImage(props.order.id, imageToRemoveId.value)
            removeModalOpened.value = false
            emitter.emit("setLoadingModal", true)
            setTimeout(() => {
                emitter.emit("setLoadingModal", false)
            }, 1000)
            setTimeout(() => {
                emitter.emit("showSuccessModal")
            }, 1000)
        }

        return {
            takePicture,
            openRemoveModal,
            removeModalOpened,
            removeImage,
            closeRemoveModal,
            toggleToFullThumb,
            mediaViewer,
            removeImageProceed
        }
    }
}
</script>