<template lang="pug">
div
    MediaViewer(
        ref="mediaViewer"
    )
        template(
            v-slot:full
        )
            vueper-slides(
                :slide-ratio="3 / 4"
                :arrow="false"
                :bullets="false"
                :infinite="false"
                ref="fullThumbsCarousel"
                v-if="order.videos?.length"
            )
                vueper-slide(
                    v-for="(fullThumbVideo, index) in order.videos"
                    :key="index"
                )
                    template(
                        v-slot:content
                    )
                        VideoThumbFull(
                            :video="fullThumbVideo"
                            @openRemoveModal="openRemoveModal(fullThumbVideo.id)"
                        )

            NoUploads(
                v-if="!order.videos?.length"
            )
                div Keine Aufnahmen
        template(
            v-slot:list
        )
            div(
                v-for="(smallThumbVideo, index) in order.videos"
                :key="index"
                :class="index != order.videos?.length - 1 ? 'mb-3' : ''"
            )
                VideoThumbSmall(
                    :video="smallThumbVideo"
                    @openRemoveModal="openRemoveModal(smallThumbVideo.id)"
                    @click="$refs.fullThumbsCarousel.goToSlide(index); $refs.mediaViewer.toggleToFullThumb()"
                )

            NoUploads(
                v-if="!order.videos?.length"
            )
                div Keine Aufnahmen

    IonRow(class="receding mt-3")
        IonCol(
            size="12"
            class="pb-0"
        )
            IonButton(
                color="secondary"
                size="large"
                expand="block"
                @click="recordVideo"
            )
                IonText(
                    color="white"
                    class="d-flex align-center"
                )
                    div(
                        style="width: 15px !important; height: 15px; background-color: white; border-radius: 50%"
                        class="mr-2"
                    )

                    h3 Aufnahme starten
        IonCol(
            size="12"
        )
            IonButton(
                color="secondary"
                size="large"
                expand="block"
            )
                IonText(
                    color="white"
                    class="d-flex align-center"
                )
                    div(
                        style="width: 15px !important; height: 15px; background-color: white"
                        class="mr-2"
                    )
                    h3 Aufnahme stoppen

CustomModal(
    v-model="removeModalOpened"
)
    ConfirmModalContent(
        @confirm="removeVideoProceed"
        @decline="closeRemoveModal"
    )
</template>

<script>
import { ref } from "vue"
import { useUtilities } from "@/composables/utilities"
import MediaViewer from "@/components/molecules/camera-uploads/MediaViewer"
import VideoThumbFull from "@/components/molecules/camera-uploads/VideoThumbFull"
import VideoThumbSmall from "@/components/molecules/camera-uploads/VideoThumbSmall"
import NoUploads from "@/components/molecules/camera-uploads/NoUploads"
import CustomModal from "@/components/molecules/modals/CustomModal"
import ConfirmModalContent from "@/components/molecules/modals/ConfirmModalContent"

import { VueperSlides, VueperSlide } from "vueperslides"
import "vueperslides/dist/vueperslides.css"

import { useState } from "@/composables/state"

export default {
    components: {
        MediaViewer,
        VideoThumbFull,
        VideoThumbSmall,
        NoUploads,
        CustomModal,
        ConfirmModalContent,
        VueperSlides,
        VueperSlide
    },

    props: {
        order: Object
    },

    setup(props) {
        // bus
        const emitter = useUtilities().getEmitter()

        // display
        const mediaViewer = ref(null)

        function toggleToFullThumb(id) {
            mediaViewer.value.toggleToFullThumb()
        }

        // recording
        function recordVideo() {}

        // modals
        const videoToRemoveId = ref()

        const removeModalOpened = ref(false)

        function openRemoveModal(id) {
            videoToRemoveId.value = id
            removeModalOpened.value = true
        }

        function closeRemoveModal() {
            videoToRemoveId.value = null
            removeModalOpened.value = false
        }

        const { removeVideo } = useState()

        function removeVideoProceed() {
            removeVideo(props.order.id, videoToRemoveId.value)
            removeModalOpened.value = false
            emitter.emit("setLoadingModal", true)
            setTimeout(() => {
                emitter.emit("setLoadingModal", false)
            }, 1000)
            setTimeout(() => {
                emitter.emit("showSuccessModal")
            }, 1000)
        }

        return {
            recordVideo,
            openRemoveModal,
            removeModalOpened,
            removeVideoProceed,
            closeRemoveModal,
            toggleToFullThumb,
            mediaViewer
        }
    }
}
</script>