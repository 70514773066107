<template lang="pug">
IonCard(
    button
    style="margin: 0"
    v-if="!!video"
    color="primary"
)
    div(
        style="width: 100%"
        class="d-flex align-center justify-space-between"
    )
        div(class="d-flex align-center")
            img(
                style="width: 100px; height: 75px; object-fit: cover"
                class="radius overflow-hidden"
                :src="video.thumb"
            )

            IonText(
                color="tertiary"
                class="ml-3"
            )
                h3 {{ video.name }}

        IonButton(
            class="icon-btn"
            @click.stop="$emit('openRemoveModal')"
        )
            //- color="var(--ion-color-primary-shade)"
            IonIcon(
                :icon="trashOutline"
                color="secondary"
            )
</template>

<script>
import { trashOutline } from "ionicons/icons"
export default {
    props: {
        video: {
            type: [Object, null]
        }
    },
    setup() {
        trashOutline
        return { trashOutline }
    }
}
</script>